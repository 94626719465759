<template>
	<Layout>
		<v-container v-if="loading">
			<v-row justify="center" align="center">
				<v-col cols="12">
					<v-col cols="12" class="text-center"><div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></v-col>
				</v-col>
			</v-row>
		</v-container>
		<v-container v-if="!loading">
			<v-row v-if="user.verificado">
				<v-col cols="12" sm="6">
					<v-card class="primary" dark rounded elevation="8">
						<div class="d-flex flex-no-wrap justify-space-between">
							<div>
								<v-card-title class="headline">MI PERFIL</v-card-title>
								<v-card-subtitle>Actualize sus datos profesionales y contraseña</v-card-subtitle>
								<v-card-actions>
                  <v-btn class="ml-2 mt-5" outlined rounded small :to="{name: 'UserEdit'}">
                    IR A MI PERFIL
                  </v-btn>
                </v-card-actions>
							</div>
							<v-avatar class="ma-3" size="100" tile>
                <v-icon size="90">mdi-account-edit</v-icon>
              </v-avatar>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6">
					<v-card class="indigo darken-1" dark rounded elevation="8">
						<div class="d-flex flex-no-wrap justify-space-between">
							<div>
								<v-card-title class="headline">CERTIFICACION</v-card-title>
								<v-card-subtitle>Descargue su Certificación como Miembro de nuestra Sociedad</v-card-subtitle>
								<v-card-actions>
                  <v-btn class="ml-2 mt-5" outlined rounded small :to="{name: 'UserCertificado'}">
                    DESCARGAR
                  </v-btn>
                </v-card-actions>
							</div>
							<v-avatar class="ma-3" size="100" tile>
                <v-icon size="90">mdi-file-certificate</v-icon>
              </v-avatar>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6">
					<v-card class="pink darken-4" dark rounded elevation="8">
						<v-card-title class="headline">RESOLUCIÓN 039</v-card-title>
						<a href="https://bit.ly/2XuYFJa" target="_blank">
							<v-img src="/images/resolucion-039.jpeg" contain />
						</a>
						<v-card-actions>
							<v-btn class="pink darken-1" href="https://bit.ly/2XuYFJa" target="_blank">AMPLIAR <v-icon class="ml-3">mdi-image</v-icon></v-btn>
							<v-spacer />
							<v-btn class="pink darken-1" href="/images/resolucion-039.pdf" target="_blank">DESCARGAR <v-icon class="ml-3">mdi-download</v-icon></v-btn>

						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>

			<v-row align="center" justify="center" v-if="!user.verificado">
				<v-col cols="12" sm="9">
						<v-alert
							class="mt-5"
							text
							prominent
							type="error"
							icon="mdi-cloud-alert"
						>
							Su registro no ha sido verificado, por favor contacte a la Directiva
						</v-alert>
				</v-col>
			</v-row>

		</v-container>
	</Layout>
</template>

<script>
	import { mapState } from 'vuex';
	import axios from "axios";
	import store from '@/store';
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	export default {
		name: 'Dashboard',
		data: () =>({
			loading: true,
			user: {
				_id: '',
				verificado: false
			}
		}),
		mounted(){
			this.getData()
		},
		methods: {
			goPerfil(){
				this.$router.push({name: 'UserEdit'})
			},
			async getData(){
				this.loading = true
				const id = this.$store.state.userModule.user._id
				try {
					const response = await axios.get(`user/query?_id=${id}` ,configuration)
					this.user._id = response.data._id
					this.user.verificado = response.data.verificado
					// console.log(this.user)
				} catch (e) {
					console.log(e.message)
				} finally {
					this.loading = false
				}

			},
		},
		computed: {

		}

	}
</script>

<style>
	.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 180px;
  height: 180px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(2, 3, 71);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>